<template>
  <MapBoxPopup
    :coordinates="coordinates"
    :offset="[0, -15]"
    :show="show"
    @close="handleClose"
  >
    <div>
      <div
        class="font-weight-bold"
        v-text="statusLabel"
      />
      <div v-if="address && address.street">
        <div v-if="address.simple_address">{{ address.simple_address }}</div>
        <div v-else>{{ address.street }}
          <span v-if="address.number">{{ address.number }}</span>
        </div>
        <div>
          <span v-text="address.postalcode" />
          <span
            class="ml-2"
            v-text="address.city"
          />
        </div>
      </div>
    </div>
  </MapBoxPopup>
</template>

<script>
import MapBoxPopup from '@/components/map/modals/MapBoxPopup'

import { mapActions, mapGetters } from 'vuex'
import { Bugfender } from '@bugfender/sdk'
import { EventBus } from '@/services/eventbus'

export default {
  name: 'ChargerDetailsPopup',
  components: { MapBoxPopup },
  data() {
    return {
      show: false,
      coordinates: null,
      status: null,
      address: null,
      doNotDeselectChargingpoint: false,
    }
  },
  computed: {
    ...mapGetters('map', [
      'getChargingPointByRefId',
      'isChargingpointSelected',
    ]),
    statusLabel () {
      if (!this.status) {
        return this.$t('chargingpoint.status.unknown')
      }
      return this.$t(`chargingpoint.status.${this.status}`)
    },
  },
  /**
   * Bind event handlers to MapBox
   */
  created() {
    this.$store.map.on('click', 'chargingpoints', this.handleClickMarkerEvent)
    EventBus.$on('deselect-chargingpoint', this.handleClose)
  },
  /**
   * Disconnect the event handlers from Mapbox
   */
  beforeDestroy() {
    this.$store.map.off('click', 'chargingpoints', this.handleClickMarkerEvent)
    EventBus.$off('deselect-chargingpoint', this.handleClose)
  },
  methods: {
    ...mapActions('map', [
      'selectChargingPoint',
      'deselectChargingPoint',
      'resetSuggestedChargingPoint',
    ]),
    /**
     * Load the clicked point's properties
     */
    handleClickMarkerEvent(e) {
      if (!e.features.length) return

      // if layer is not visible don't react to clicks
      if (e.features[0].layer.paint['icon-opacity'] === 0) return

      // Cancel other map events
      e.preventDefault()
      e.originalEvent.stopPropagation()

      // Get the charging point to get the exact coordinates & user details
      let chargingpoint = this.getChargingPointByRefId({
        refId: e.features[0].properties.refId,
      })

      // if there is already a popup opened, set a flag to not deselect the chargingpoint in the store.
      // This is to avoid a flickering of the sidebar, since we need to wait for the nextTick and the current popup is closed
      if (this.isChargingpointSelected && this.show) {
        this.doNotDeselectChargingpoint = true
      }

      this.show = false

      this.$nextTick(() => {
        this.selectChargingPoint({ refId: chargingpoint.ref['@ref'].id })

        if (!chargingpoint.properties.status) {
          Bugfender.warn('Chargingpoint does not have a status', chargingpoint.ref['@ref'].id)
        }

        this.status = chargingpoint.properties.status
        this.address = chargingpoint.address
        this.coordinates = chargingpoint.coordinates

        this.show = true
        this.doNotDeselectChargingpoint = false
      })
    },
    /**
     * Upon the close event of the popup
     */
    handleClose() {
      this.show = false

      // if this flag is set, we don't want to deselect the chargingpoint in the store to avoid a flickr,
      // while switching between chargingpoints on the map
      if (this.doNotDeselectChargingpoint === false) {
        this.deselectChargingPoint()
        this.resetSuggestedChargingPoint()
      }
    },
  },
}
</script>

<style lang="scss">
.mapboxgl-popup-content {
  padding: 10px 20px 10px 10px !important;

  p {
    margin: 0;
    user-select: auto;
  }
}
</style>
